@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.table {
  .tr-trademark {
    &.has-transliterations {
      padding-bottom: map_get($spacers, 1);
    }
  }

  .tr-transliteration {
    td {
      border-top: none;
      font-size: 0.9rem;
    }

    &:not(.tr-last) td {
      border-bottom: none;
    }
  }
}

tr.level-high td {
  background-color: #ff000030;
}

tr.level-medium td {
  background-color: #ffc80029;
}

tr.level-low td {
  // background-color: green;
}
